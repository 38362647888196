import React, { useEffect, useState } from "react";
import RedeemCard from "./RedeemCard.js";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { getCustomerGiftcard, voucherTemplateDetails } from "../../services/services.js";
import Loader from "../partial/Loader.js";
import { voucher, giftCard, backArrow, website_banner } from "../../Image/Index.js";

const ChooseRewardLive = () => {
    const navigate = useNavigate();

    const location = useLocation();

    // const walletsDetail = location?.state?.walletsDetail
    const [state, setState] = useState({

        template: [],
        walletsDetail: {}



    });
    // console.log(walletsDetail)


    const [loader, setLoader] = useState(true);
    const [rewardState, setRewardState] = useState({
        giftCards: [],
        detail: "",
        type: "",
    });
    const [showDetail, setShowDetail] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        const fetch = async () => {
            setLoader(true)
            let tempTemplate = [];
            const token = JSON.parse(localStorage.getItem("token_gen"));
           await voucherTemplateDetails(token?.access_token)
                .then((res) => {
                    if (res?.data) {
                        const currentDate = new Date();
                        res?.data?.map((e) => e.isActive && new Date(e.voucherVisibilityDate) <= currentDate && tempTemplate.push(e));
                    }
                    setState((prevState) => ({
                        ...prevState,

                        template: tempTemplate,
                        walletsDetail: location?.state?.walletsDetail

                    }));
                  
                })
                .catch(() => {
                });

                const id = localStorage.getItem("Id");
                await getCustomerGiftcard(id).then((res)=>{
                    setRewardState((prevState) => ({
                        ...prevState,
                        giftCards: res?.data,
                    }));
        
                })
                .catch(() => {
                });
                setLoader(false)



        };
        fetch();
    }, []);
    const { template, walletsDetail } = state;

    const { giftCards, detail, type } = rewardState;

    useEffect(() => {
        if (selectedValue) {
            if (location?.state?.type === "internal") {
                setSelectedValue("internal");
            } else {
                setSelectedValue("external");
            }
        }
        // eslint-disable-next-line
    }, [location]);

    // useEffect(() => {
    //     const fetch = async () => {
    //         const id = localStorage.getItem("Id");
    //         const giftcard = await getCustomerGiftcard(id);

    //         setRewardState((prevState) => ({
    //             ...prevState,
    //             giftCards: giftcard?.data,
    //         }));

    //     };
    //     fetch();
    // }, []);

    const internalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : template?.length > 0 ? (
                    <div className="internal_voucher_row">
                        {template?.map((vou, i) => (
                            <div className="internal_voucher_box" key={i}>
                                <Link
                                    to=""
                                    state={{ selectedValue }}
                                    onClick={() => {
                                        setRewardState((prev) => ({
                                            ...prev,
                                            detail: { ...vou, earningValue: vou.earningValue },
                                            type: "voucher",
                                        }));
                                        setShowDetail((prev) => !prev);
                                    }}
                                >
                                    <div className="internal_voucher_ibox">
                                        <img src={vou?.imageUpload ? vou.imageUpload : voucher} alt="" />
                                    </div>
                                    <div className="internal_voucher_cbox">
                                        <h5>{vou.name}</h5>
                                        <p dangerouslySetInnerHTML={vou.description ? { __html: vou.description } : null}></p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };

    const externalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : giftCards?.length > 0 ? (
                    <div className="internal_giftcard_row">
                        {giftCards?.map((ele, index) => (
                            <div className="internal_giftcard_box" key={index}>
                                <Link
                                    to=""
                                    state={{ selectedValue }}
                                    onClick={() => {
                                        setRewardState((prev) => ({
                                            ...prev,
                                            detail: ele,
                                            type: "giftCard",
                                        }));
                                        setShowDetail(() => !showDetail);
                                    }}
                                >
                                    <div className="internal_giftcard_ibox">{<img src={ele?.image ? ele?.image : giftCard} alt={ele.title} />}</div>
                                    <div className="internal_giftcard_cbox">
                                        <h5>{ele.title}</h5>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };

    const handleSelect = (e) => {
        if (e.target.value === "internal") {
            setSelectedValue(e.target.value);
        } else if (e.target.value === "external") {
            setSelectedValue(e.target.value);
        } else {
            setSelectedValue("");
        }
    };

    return (
        <div>
            {loader && <Loader />}
            <div className="customer_portal__mainPage">
                <div className="hero-banner">
                    <img src={website_banner} alt="Hero Banner" />
                </div>
                {!showDetail ? (
                    <div className="allCardContainer">
                        <div className="function_row">
                            <div className="function_back">
                                <Link className="backAro" onClick={() => navigate(-1)}>
                                    <img src={backArrow} alt="Back" height={16} /> &nbsp; Back
                                </Link>
                            </div>
                            <div className="d-flex justify-content-end align-items-center px-1">
                                <div className="chooseVc">Choose a Giftcard/Voucher</div>
                                <div>
                                    <select className="form-select form-select-sm" name="vouchers" id="vouchers" value={selectedValue} onChange={handleSelect}>
                                        <option value="all">All</option>
                                        <option value="internal">Local Merchant Voucher</option>
                                        <option value="external">International Voucher</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="allCardContainer__top">

                            {selectedValue === "internal" ? (
                                internalFun()
                            ) : selectedValue === "external" ? (
                                externalFun()
                            ) : (
                                <div>
                                    {loader ? (
                                        ""
                                    ) : (
                                        <h6 className="voucher-heading">
                                            <span>Local Merchant Voucher</span>
                                        </h6>
                                    )}
                                    {internalFun()}
                                    {loader ? (
                                        ""
                                    ) : (
                                        <h6 className="voucher-heading">
                                            <span>International Voucher</span>
                                        </h6>
                                    )}
                                    {externalFun()}
                                </div>
                            )}
                        </div>
                    </div>

                ) : (
                    <RedeemCard detail={detail} type={type} walletsDetail={walletsDetail} setShowDetail={setShowDetail} />
                )}

            </div>
            <div className="footer__Box">
                <div className="inner_footer">
                    <div className="inner_footerbox inner_footerbox_1">
                        <h4>Quick Links</h4>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/chooseReward">Rewards</Link></li>
                            <li><Link to="/customer-portal?tab=history">History</Link></li>
                            <li><Link to="/customer-portal?tab=voucher">Vouchers</Link></li>
                            <li><Link to="/customer-portal?tab=giftcard">Gift Cards</Link></li>
                        </ul>
                    </div>
                    <div className="inner_footerbox inner_footerbox_2">
                        <h4>Our Location</h4>
                        <p>Maritime Centre <br />29 Tenth Avenue <br />Barataria, <br />Trinidad, West Indies</p>
                    </div>
                    <div className="inner_footerbox inner_footerbox_3">
                        <h4>Contact Us</h4>
                        <ul>
                            <li><a href="tel:+1868-607-6274">+1868-607-6274</a></li>
                            <li><a href="tel:+1868-674-0130">+1868-674-0130</a></li>
                            <li><a href="mailto:email@maritimefinancial.com">email@maritimefinancial.com</a></li>
                        </ul>
                    </div>
                </div>
                <div className="copyright_box">
                    <div className="copyright_innerbox">
                        <div className="copyright_textbox">
                            <ul>
                                <li>© 2023 The Maritime Financial Group. All Rights Reserved</li>
                                <li><a href="https://maritimefinancial.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                            </ul>
                        </div>
                        <div className="copyright_arrowbox">
                            <a href="#" id="totopbutton"><span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChooseRewardLive;